// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_NL_BE: CharacterDict = {
  Backquote: [/* ² */ 0x00b2, /* ³ */ 0x00b3],
  Digit1: [/* & */ 0x0026, /* 1 */ 0x0031, /* | */ 0x007c],
  Digit2: [/* é */ 0x00e9, /* 2 */ 0x0032, /* @ */ 0x0040],
  Digit3: [/* " */ 0x0022, /* 3 */ 0x0033, /* # */ 0x0023],
  Digit4: [/* ' */ 0x0027, /* 4 */ 0x0034, /* { */ 0x007b],
  Digit5: [/* ( */ 0x0028, /* 5 */ 0x0035, /* [ */ 0x005b],
  Digit6: [/* § */ 0x00a7, /* 6 */ 0x0036, /* ^ */ 0x005e],
  Digit7: [/* è */ 0x00e8, /* 7 */ 0x0037],
  Digit8: [/* ! */ 0x0021, /* 8 */ 0x0038],
  Digit9: [/* ç */ 0x00e7, /* 9 */ 0x0039, /* { */ 0x007b],
  Digit0: [/* à */ 0x00e0, /* 0 */ 0x0030, /* } */ 0x007d],
  Minus: [/* ) */ 0x0029, /* ° */ 0x00b0],
  Equal: [/* - */ 0x002d, /* _ */ 0x005f],
  KeyQ: [/* a */ 0x0061, /* A */ 0x0041],
  KeyW: [/* z */ 0x007a, /* Z */ 0x005a],
  KeyE: [/* e */ 0x0065, /* E */ 0x0045, /* € */ 0x20ac],
  KeyR: [/* r */ 0x0072, /* R */ 0x0052],
  KeyT: [/* t */ 0x0074, /* T */ 0x0054],
  KeyY: [/* y */ 0x0079, /* Y */ 0x0059],
  KeyU: [/* u */ 0x0075, /* U */ 0x0055],
  KeyI: [/* i */ 0x0069, /* I */ 0x0049],
  KeyO: [/* o */ 0x006f, /* O */ 0x004f],
  KeyP: [/* p */ 0x0070, /* P */ 0x0050],
  BracketLeft: [{ dead: /* COMBINING CIRCUMFLEX ACCENT */ 0x0302 }, { dead: /* COMBINING DIAERESIS */ 0x0308 }, /* [ */ 0x005b],
  BracketRight: [/* $ */ 0x0024, /* * */ 0x002a, /* ] */ 0x005d],
  Backslash: [/* µ */ 0x00b5, /* £ */ 0x00a3, { dead: /* COMBINING GRAVE ACCENT */ 0x0300 }, { dead: /* COMBINING GRAVE ACCENT */ 0x0300 }],
  KeyA: [/* q */ 0x0071, /* Q */ 0x0051],
  KeyS: [/* s */ 0x0073, /* S */ 0x0053],
  KeyD: [/* d */ 0x0064, /* D */ 0x0044],
  KeyF: [/* f */ 0x0066, /* F */ 0x0046],
  KeyG: [/* g */ 0x0067, /* G */ 0x0047],
  KeyH: [/* h */ 0x0068, /* H */ 0x0048],
  KeyJ: [/* j */ 0x006a, /* J */ 0x004a],
  KeyK: [/* k */ 0x006b, /* K */ 0x004b],
  KeyL: [/* l */ 0x006c, /* L */ 0x004c],
  Semicolon: [/* m */ 0x006d, /* M */ 0x004d],
  Quote: [/* ù */ 0x00f9, /* % */ 0x0025, { dead: /* COMBINING ACUTE ACCENT */ 0x0301 }, { dead: /* COMBINING ACUTE ACCENT */ 0x0301 }],
  IntlBackslash: [/* < */ 0x003c, /* > */ 0x003e, /* \ */ 0x005c],
  KeyZ: [/* w */ 0x0077, /* W */ 0x0057],
  KeyX: [/* x */ 0x0078, /* X */ 0x0058],
  KeyC: [/* c */ 0x0063, /* C */ 0x0043],
  KeyV: [/* v */ 0x0076, /* V */ 0x0056],
  KeyB: [/* b */ 0x0062, /* B */ 0x0042],
  KeyN: [/* n */ 0x006e, /* N */ 0x004e],
  KeyM: [/* , */ 0x002c, /* ? */ 0x003f],
  Comma: [/* ; */ 0x003b, /* . */ 0x002e],
  Period: [/* : */ 0x003a, /* / */ 0x002f],
  Slash: [/* = */ 0x003d, /* + */ 0x002b, { dead: /* COMBINING TILDE */ 0x0303 }, { dead: /* COMBINING TILDE */ 0x0303 }],
  Space: [/* SPACE */ 0x0020],
};
