// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_FA_IR: CharacterDict = {
  Backquote: [{ special: /* ZERO WIDTH JOINER */ 0x200d }, { special: /* ZERO WIDTH JOINER */ 0x200d }],
  Digit1: [/* ۱ */ 0x06f1, /* ! */ 0x0021],
  Digit2: [/* ۲ */ 0x06f2, /* ٬ */ 0x066c],
  Digit3: [/* ۳ */ 0x06f3, /* ٫ */ 0x066b],
  Digit4: [/* ۴ */ 0x06f4, { ligature: "ریال" }],
  Digit5: [/* ۵ */ 0x06f5, /* ٪ */ 0x066a],
  Digit6: [/* ۶ */ 0x06f6, /* × */ 0x00d7],
  Digit7: [/* ۷ */ 0x06f7, /* ، */ 0x060c],
  Digit8: [/* ۸ */ 0x06f8, /* * */ 0x002a],
  Digit9: [/* ۹ */ 0x06f9, /* ) */ 0x0029],
  Digit0: [/* ۰ */ 0x06f0, /* ( */ 0x0028],
  Minus: [/* - */ 0x002d, /* ـ */ 0x0640],
  Equal: [/* = */ 0x003d, /* + */ 0x002b],
  KeyQ: [/* ض */ 0x0636, /* ْ */ 0x0652],
  KeyW: [/* ص */ 0x0635, /* ٌ */ 0x064c],
  KeyE: [/* ث */ 0x062b, /* ٍ */ 0x064d],
  KeyR: [/* ق */ 0x0642, /* ً */ 0x064b],
  KeyT: [/* ف */ 0x0641, /* ُ */ 0x064f],
  KeyY: [/* غ */ 0x063a, /* ِ */ 0x0650],
  KeyU: [/* ع */ 0x0639, /* َ */ 0x064e],
  KeyI: [/* ه */ 0x0647, /* ّ */ 0x0651],
  KeyO: [/* خ */ 0x062e, /* ] */ 0x005d],
  KeyP: [/* ح */ 0x062d, /* [ */ 0x005b],
  BracketLeft: [/* ج */ 0x062c, /* } */ 0x007d],
  BracketRight: [/* چ */ 0x0686, /* { */ 0x007b],
  Backslash: [/* \ */ 0x005c, /* | */ 0x007c],
  KeyA: [/* ش */ 0x0634, /* ؤ */ 0x0624],
  KeyS: [/* س */ 0x0633, /* ئ */ 0x0626],
  KeyD: [/* ی */ 0x06cc, /* ي */ 0x064a],
  KeyF: [/* ب */ 0x0628, /* إ */ 0x0625],
  KeyG: [/* ل */ 0x0644, /* أ */ 0x0623],
  KeyH: [/* ا */ 0x0627, /* آ */ 0x0622],
  KeyJ: [/* ت */ 0x062a, /* ة */ 0x0629],
  KeyK: [/* ن */ 0x0646, /* » */ 0x00bb],
  KeyL: [/* م */ 0x0645, /* « */ 0x00ab],
  Semicolon: [/* ک */ 0x06a9, /* : */ 0x003a],
  Quote: [/* گ */ 0x06af, /* ؛ */ 0x061b],
  IntlBackslash: [/* \ */ 0x005c, /* | */ 0x007c],
  KeyZ: [/* ظ */ 0x0638, /* ك */ 0x0643],
  KeyX: [/* ط */ 0x0637, /* ط */ 0x0637],
  KeyC: [/* ز */ 0x0632, /* ژ */ 0x0698],
  KeyV: [/* ر */ 0x0631, /* ٰ */ 0x0670],
  KeyB: [/* ذ */ 0x0630, { special: /* ZERO WIDTH NON-JOINER */ 0x200c }],
  KeyN: [/* د */ 0x062f, /* ٔ */ 0x0654],
  KeyM: [/* پ */ 0x067e, /* ء */ 0x0621],
  Comma: [/* و */ 0x0648, /* > */ 0x003e],
  Period: [/* . */ 0x002e, /* < */ 0x003c],
  Slash: [/* / */ 0x002f, /* ؟ */ 0x061f],
  Space: [/* SPACE */ 0x0020],
};
