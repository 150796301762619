// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_FR_ERGO_L: CharacterDict = {
  Backquote: [/* ` */ 0x0060, /* ~ */ 0x007e],
  Digit1: [/* 1 */ 0x0031, /* € */ 0x20ac, /* ₁ */ 0x2081, /* ¹ */ 0x00b9],
  Digit2: [/* 2 */ 0x0032, /* « */ 0x00ab, /* ₂ */ 0x2082, /* ² */ 0x00b2],
  Digit3: [/* 3 */ 0x0033, /* » */ 0x00bb, /* ₃ */ 0x2083, /* ³ */ 0x00b3],
  Digit4: [/* 4 */ 0x0034, /* $ */ 0x0024, /* ₄ */ 0x2084, /* ⁴ */ 0x2074],
  Digit5: [/* 5 */ 0x0035, /* % */ 0x0025, /* ₅ */ 0x2085, /* ⁵ */ 0x2075],
  Digit6: [/* 6 */ 0x0036, /* ^ */ 0x005e, /* ₆ */ 0x2086, /* ⁶ */ 0x2076],
  Digit7: [/* 7 */ 0x0037, /* & */ 0x0026, /* ₇ */ 0x2087, /* ⁷ */ 0x2077],
  Digit8: [/* 8 */ 0x0038, /* * */ 0x002a, /* ₈ */ 0x2088, /* ⁸ */ 0x2078],
  Digit9: [/* 9 */ 0x0039, /* # */ 0x0023, /* ₉ */ 0x2089, /* ⁹ */ 0x2079],
  Digit0: [/* 0 */ 0x0030, /* @ */ 0x0040, /* ₀ */ 0x2080, /* ⁰ */ 0x2070],
  Minus: [/* / */ 0x002f, /* _ */ 0x005f],
  Equal: [/* = */ 0x003d, /* + */ 0x002b],
  KeyQ: [/* q */ 0x0071, /* Q */ 0x0051, /* ^ */ 0x005e, { dead: /* COMBINING CIRCUMFLEX ACCENT */ 0x0302 }],
  KeyW: [/* c */ 0x0063, /* C */ 0x0043, /* < */ 0x003c, /* ≤ */ 0x2264],
  KeyE: [/* o */ 0x006f, /* O */ 0x004f, /* > */ 0x003e, /* ≥ */ 0x2265],
  KeyR: [/* p */ 0x0070, /* P */ 0x0050, /* $ */ 0x0024],
  KeyT: [/* w */ 0x0077, /* W */ 0x0057, /* % */ 0x0025, /* ‰ */ 0x2030],
  KeyY: [/* j */ 0x006a, /* J */ 0x004a, /* @ */ 0x0040],
  KeyU: [/* m */ 0x006d, /* M */ 0x004d, /* & */ 0x0026],
  KeyI: [/* d */ 0x0064, /* D */ 0x0044, /* * */ 0x002a, /* × */ 0x00d7],
  KeyO: [{ dead: /* * */ 0x002a }, /* ! */ 0x0021, /* ' */ 0x0027, { dead: /* COMBINING ACUTE ACCENT */ 0x0301 }],
  KeyP: [/* y */ 0x0079, /* Y */ 0x0059, /* ` */ 0x0060, { dead: /* COMBINING GRAVE ACCENT */ 0x0300 }],
  BracketLeft: [/* [ */ 0x005b, /* { */ 0x007b],
  BracketRight: [/* ] */ 0x005d, /* } */ 0x007d],
  Backslash: [/* \ */ 0x005c, /* | */ 0x007c],
  KeyA: [/* a */ 0x0061, /* A */ 0x0041, /* { */ 0x007b, { dead: /* COMBINING CARON */ 0x030c }],
  KeyS: [/* s */ 0x0073, /* S */ 0x0053, /* ( */ 0x0028],
  KeyD: [/* e */ 0x0065, /* E */ 0x0045, /* ) */ 0x0029],
  KeyF: [/* n */ 0x006e, /* N */ 0x004e, /* } */ 0x007d, { dead: /* COMBINING DOT ABOVE */ 0x0307 }],
  KeyG: [/* f */ 0x0066, /* F */ 0x0046, /* = */ 0x003d, /* ≠ */ 0x2260],
  KeyH: [/* l */ 0x006c, /* L */ 0x004c, /* \ */ 0x005c],
  KeyJ: [/* r */ 0x0072, /* R */ 0x0052, /* + */ 0x002b, /* ± */ 0x00b1],
  KeyK: [/* t */ 0x0074, /* T */ 0x0054, /* - */ 0x002d, { dead: /* COMBINING MACRON */ 0x0304 }],
  KeyL: [/* i */ 0x0069, /* I */ 0x0049, /* / */ 0x002f, /* ÷ */ 0x00f7],
  Semicolon: [/* u */ 0x0075, /* U */ 0x0055, /* " */ 0x0022],
  Quote: [/* ' */ 0x0027, /* " */ 0x0022],
  IntlBackslash: [/* < */ 0x003c, /* > */ 0x003e],
  KeyZ: [/* z */ 0x007a, /* Z */ 0x005a, /* ~ */ 0x007e, { dead: /* COMBINING TILDE */ 0x0303 }],
  KeyX: [/* x */ 0x0078, /* X */ 0x0058, /* [ */ 0x005b],
  KeyC: [/* - */ 0x002d, /* ? */ 0x003f, /* ] */ 0x005d, { dead: /* COMBINING OGONEK */ 0x0328 }],
  KeyV: [/* v */ 0x0076, /* V */ 0x0056, /* _ */ 0x005f, /* – */ 0x2013],
  KeyB: [/* b */ 0x0062, /* B */ 0x0042, /* # */ 0x0023],
  KeyN: [/* . */ 0x002e, /* : */ 0x003a, /* | */ 0x007c, /* ¦ */ 0x00a6],
  KeyM: [/* h */ 0x0068, /* H */ 0x0048, /* ! */ 0x0021, /* ¬ */ 0x00ac],
  Comma: [/* g */ 0x0067, /* G */ 0x0047, /* ; */ 0x003b, { dead: /* COMBINING CEDILLA */ 0x0327 }],
  Period: [/* , */ 0x002c, /* ; */ 0x003b, /* : */ 0x003a, /* : */ 0x003a],
  Slash: [/* k */ 0x006b, /* K */ 0x004b, /* ? */ 0x003f, { dead: /* COMBINING BREVE */ 0x0306 }],
  Space: [/* SPACE */ 0x0020],
};
