// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_JA_JP_JIS: CharacterDict = {
  Digit1: [/* 1 */ 0x0031, /* ! */ 0x0021, /* ぬ */ 0x306c],
  Digit2: [/* 2 */ 0x0032, /* " */ 0x0022, /* ふ */ 0x3075],
  Digit3: [/* 3 */ 0x0033, /* # */ 0x0023, /* あ */ 0x3042, /* ぁ */ 0x3041],
  Digit4: [/* 4 */ 0x0034, /* $ */ 0x0024, /* う */ 0x3046, /* ぅ */ 0x3045],
  Digit5: [/* 5 */ 0x0035, /* % */ 0x0025, /* え */ 0x3048, /* ぇ */ 0x3047],
  Digit6: [/* 6 */ 0x0036, /* & */ 0x0026, /* お */ 0x304a, /* ぉ */ 0x3049],
  Digit7: [/* 7 */ 0x0037, /* ' */ 0x0027, /* や */ 0x3084, /* ゃ */ 0x3083],
  Digit8: [/* 8 */ 0x0038, /* ( */ 0x0028, /* ゆ */ 0x3086, /* ゅ */ 0x3085],
  Digit9: [/* 9 */ 0x0039, /* ) */ 0x0029, /* よ */ 0x3088, /* ょ */ 0x3087],
  Digit0: [/* 0 */ 0x0030, null, /* わ */ 0x308f, /* を */ 0x3092],
  Minus: [/* - */ 0x002d, /* = */ 0x003d, /* ほ */ 0x307b],
  Equal: [/* ^ */ 0x005e, /* ~ */ 0x007e, /* へ */ 0x3078],
  IntlYen: [/* ¥ */ 0x00a5, /* _ */ 0x005f],
  KeyQ: [/* Q */ 0x0051, /* q */ 0x0071, /* た */ 0x305f],
  KeyW: [/* W */ 0x0057, /* w */ 0x0077, /* て */ 0x3066],
  KeyE: [/* E */ 0x0045, /* e */ 0x0065, /* い */ 0x3044, /* ぃ */ 0x3043],
  KeyR: [/* R */ 0x0052, /* r */ 0x0072, /* す */ 0x3059],
  KeyT: [/* T */ 0x0054, /* t */ 0x0074, /* か */ 0x304b],
  KeyY: [/* Y */ 0x0059, /* y */ 0x0079, /* ん */ 0x3093],
  KeyU: [/* U */ 0x0055, /* u */ 0x0075, /* な */ 0x306a],
  KeyI: [/* I */ 0x0049, /* i */ 0x0069, /* に */ 0x306b],
  KeyO: [/* O */ 0x004f, /* o */ 0x006f, /* ら */ 0x3089],
  KeyP: [/* P */ 0x0050, /* p */ 0x0070, /* せ */ 0x305b],
  BracketLeft: [/* @ */ 0x0040, /* ` */ 0x0060, /* ゛ */ 0x309b],
  BracketRight: [/* [ */ 0x005b, /* { */ 0x007b, /* ○ */ 0x25cb, /* 「 */ 0x300c],
  Backslash: [/* ] */ 0x005d, /* } */ 0x007d, /* む */ 0x3080, /* 」 */ 0x300d],
  KeyA: [/* A */ 0x0041, /* a */ 0x0061, /* ち */ 0x3061],
  KeyS: [/* S */ 0x0053, /* s */ 0x0073, /* と */ 0x3068],
  KeyD: [/* D */ 0x0044, /* d */ 0x0064, /* し */ 0x3057],
  KeyF: [/* F */ 0x0046, /* f */ 0x0066, /* は */ 0x306f],
  KeyG: [/* G */ 0x0047, /* g */ 0x0067, /* き */ 0x304d],
  KeyH: [/* H */ 0x0048, /* h */ 0x0068, /* く */ 0x304f],
  KeyJ: [/* J */ 0x004a, /* j */ 0x006a, /* ま */ 0x307e],
  KeyK: [/* K */ 0x004b, /* k */ 0x006b, /* の */ 0x306e],
  KeyL: [/* L */ 0x004c, /* l */ 0x006c, /* り */ 0x308a],
  Semicolon: [/* ; */ 0x003b, /* + */ 0x002b, /* れ */ 0x308c],
  Quote: [/* : */ 0x003a, /* * */ 0x002a, /* け */ 0x3051],
  IntlBackslash: [/* ] */ 0x005d, /* } */ 0x007d, /* む */ 0x3080, /* 」 */ 0x300d],
  KeyZ: [/* Z */ 0x005a, /* z */ 0x007a, /* つ */ 0x3064, /* っ */ 0x3063],
  KeyX: [/* X */ 0x0058, /* x */ 0x0078, /* さ */ 0x3055],
  KeyC: [/* C */ 0x0043, /* c */ 0x0063, /* そ */ 0x305d],
  KeyV: [/* V */ 0x0056, /* v */ 0x0076, /* ひ */ 0x3072],
  KeyB: [/* B */ 0x0042, /* b */ 0x0062, /* こ */ 0x3053],
  KeyN: [/* N */ 0x004e, /* n */ 0x006e, /* み */ 0x307f],
  KeyM: [/* M */ 0x004d, /* m */ 0x006d, /* も */ 0x3082],
  Comma: [/* , */ 0x002c, /* < */ 0x003c, /* ね */ 0x306d, /* ` */ 0x0060],
  Period: [/* . */ 0x002e, /* > */ 0x003e, /* る */ 0x308b, /* ○ */ 0x25cb],
  Slash: [/* / */ 0x002f, /* ? */ 0x003f, /* め */ 0x3081, /* ● */ 0x25cf],
  IntlRo: [/* \ */ 0x005c, /* ろ */ 0x308d],
  Space: [/* SPACE */ 0x0020],
};
