// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_FA_IR_LEGACY: CharacterDict = {
  Backquote: [/* ÷ */ 0x00f7, /* × */ 0x00d7],
  Digit1: [/* 1 */ 0x0031, /* ! */ 0x0021],
  Digit2: [/* 2 */ 0x0032, /* @ */ 0x0040],
  Digit3: [/* 3 */ 0x0033, /* # */ 0x0023],
  Digit4: [/* 4 */ 0x0034, /* $ */ 0x0024],
  Digit5: [/* 5 */ 0x0035, /* % */ 0x0025],
  Digit6: [/* 6 */ 0x0036, /* ^ */ 0x005e],
  Digit7: [/* 7 */ 0x0037, /* & */ 0x0026],
  Digit8: [/* 8 */ 0x0038, /* * */ 0x002a],
  Digit9: [/* 9 */ 0x0039, /* ) */ 0x0029],
  Digit0: [/* 0 */ 0x0030, /* ( */ 0x0028],
  Minus: [/* - */ 0x002d, /* _ */ 0x005f],
  Equal: [/* = */ 0x003d, /* + */ 0x002b],
  KeyQ: [/* ض */ 0x0636, /* ً */ 0x064b],
  KeyW: [/* ص */ 0x0635, /* ٌ */ 0x064c],
  KeyE: [/* ث */ 0x062b, /* ٍ */ 0x064d],
  KeyR: [/* ق */ 0x0642, { ligature: "ريال" }],
  KeyT: [/* ف */ 0x0641, /* ، */ 0x060c],
  KeyY: [/* غ */ 0x063a, /* ؛ */ 0x061b],
  KeyU: [/* ع */ 0x0639, /* , */ 0x002c],
  KeyI: [/* ه */ 0x0647, /* ] */ 0x005d],
  KeyO: [/* خ */ 0x062e, /* [ */ 0x005b],
  KeyP: [/* ح */ 0x062d, /* \ */ 0x005c],
  BracketLeft: [/* ج */ 0x062c, /* } */ 0x007d],
  BracketRight: [/* چ */ 0x0686, /* { */ 0x007b],
  Backslash: [/* پ */ 0x067e, /* | */ 0x007c],
  KeyA: [/* ش */ 0x0634, /* َ */ 0x064e],
  KeyS: [/* س */ 0x0633, /* ُ */ 0x064f],
  KeyD: [/* ی */ 0x06cc, /* ِ */ 0x0650],
  KeyF: [/* ب */ 0x0628, /* ّ */ 0x0651],
  KeyG: [/* ل */ 0x0644, /* ۀ */ 0x06c0],
  KeyH: [/* ا */ 0x0627, /* آ */ 0x0622],
  KeyJ: [/* ت */ 0x062a, /* ـ */ 0x0640],
  KeyK: [/* ن */ 0x0646, /* « */ 0x00ab],
  KeyL: [/* م */ 0x0645, /* » */ 0x00bb],
  Semicolon: [/* ک */ 0x06a9, /* : */ 0x003a],
  Quote: [/* گ */ 0x06af, /* " */ 0x0022],
  IntlBackslash: [/* پ */ 0x067e, /* | */ 0x007c],
  KeyZ: [/* ظ */ 0x0638, /* ة */ 0x0629],
  KeyX: [/* ط */ 0x0637, /* ي */ 0x064a],
  KeyC: [/* ز */ 0x0632, /* ژ */ 0x0698],
  KeyV: [/* ر */ 0x0631, /* ؤ */ 0x0624],
  KeyB: [/* ذ */ 0x0630, /* إ */ 0x0625],
  KeyN: [/* د */ 0x062f, /* أ */ 0x0623],
  KeyM: [/* ئ */ 0x0626, /* ء */ 0x0621],
  Comma: [/* و */ 0x0648, /* < */ 0x003c],
  Period: [/* . */ 0x002e, /* > */ 0x003e],
  Slash: [/* / */ 0x002f, /* ؟ */ 0x061f],
  Space: [/* SPACE */ 0x0020],
};
