// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_EL_GR: CharacterDict = {
  Backquote: [/* ` */ 0x0060, /* ~ */ 0x007e],
  Digit1: [/* 1 */ 0x0031, /* ! */ 0x0021],
  Digit2: [/* 2 */ 0x0032, /* @ */ 0x0040, /* ² */ 0x00b2],
  Digit3: [/* 3 */ 0x0033, /* # */ 0x0023, /* ³ */ 0x00b3],
  Digit4: [/* 4 */ 0x0034, /* $ */ 0x0024, /* £ */ 0x00a3],
  Digit5: [/* 5 */ 0x0035, /* % */ 0x0025, /* § */ 0x00a7],
  Digit6: [/* 6 */ 0x0036, /* ^ */ 0x005e, /* ¶ */ 0x00b6],
  Digit7: [/* 7 */ 0x0037, /* & */ 0x0026],
  Digit8: [/* 8 */ 0x0038, /* * */ 0x002a, /* ¤ */ 0x00a4],
  Digit9: [/* 9 */ 0x0039, /* ( */ 0x0028, /* ¦ */ 0x00a6],
  Digit0: [/* 0 */ 0x0030, /* ) */ 0x0029, /* ° */ 0x00b0],
  Minus: [/* - */ 0x002d, /* _ */ 0x005f, /* ± */ 0x00b1],
  Equal: [/* = */ 0x003d, /* + */ 0x002b, /* ½ */ 0x00bd],
  KeyQ: [/* ; */ 0x003b, /* : */ 0x003a],
  KeyW: [/* ς */ 0x03c2],
  KeyE: [/* ε */ 0x03b5, /* Ε */ 0x0395, /* € */ 0x20ac],
  KeyR: [/* ρ */ 0x03c1, /* Ρ */ 0x03a1, /* ® */ 0x00ae],
  KeyT: [/* τ */ 0x03c4, /* Τ */ 0x03a4],
  KeyY: [/* υ */ 0x03c5, /* Υ */ 0x03a5, /* ¥ */ 0x00a5],
  KeyU: [/* θ */ 0x03b8, /* Θ */ 0x0398],
  KeyI: [/* ι */ 0x03b9, /* Ι */ 0x0399],
  KeyO: [/* ο */ 0x03bf, /* Ο */ 0x039f],
  KeyP: [/* π */ 0x03c0, /* Π */ 0x03a0],
  BracketLeft: [/* [ */ 0x005b, /* { */ 0x007b, /* « */ 0x00ab],
  BracketRight: [/* ] */ 0x005d, /* } */ 0x007d, /* » */ 0x00bb],
  Backslash: [/* \ */ 0x005c, /* | */ 0x007c, /* ¬ */ 0x00ac],
  KeyA: [/* α */ 0x03b1, /* Α */ 0x0391],
  KeyS: [/* σ */ 0x03c3, /* Σ */ 0x03a3],
  KeyD: [/* δ */ 0x03b4, /* Δ */ 0x0394],
  KeyF: [/* φ */ 0x03c6, /* Φ */ 0x03a6],
  KeyG: [/* γ */ 0x03b3, /* Γ */ 0x0393],
  KeyH: [/* η */ 0x03b7, /* Η */ 0x0397],
  KeyJ: [/* ξ */ 0x03be, /* Ξ */ 0x039e],
  KeyK: [/* κ */ 0x03ba, /* Κ */ 0x039a],
  KeyL: [/* λ */ 0x03bb, /* Λ */ 0x039b],
  Semicolon: [{ dead: /* COMBINING ACUTE ACCENT */ 0x0301 }, { dead: /* COMBINING DIAERESIS */ 0x0308 }],
  Quote: [/* ' */ 0x0027, /* " */ 0x0022],
  IntlBackslash: [/* < */ 0x003c, /* > */ 0x003e],
  KeyZ: [/* ζ */ 0x03b6, /* Ζ */ 0x0396],
  KeyX: [/* χ */ 0x03c7, /* Χ */ 0x03a7],
  KeyC: [/* ψ */ 0x03c8, /* Ψ */ 0x03a8, /* © */ 0x00a9],
  KeyV: [/* ω */ 0x03c9, /* Ω */ 0x03a9],
  KeyB: [/* β */ 0x03b2, /* Β */ 0x0392],
  KeyN: [/* ν */ 0x03bd, /* Ν */ 0x039d],
  KeyM: [/* μ */ 0x03bc, /* Μ */ 0x039c],
  Comma: [/* , */ 0x002c, /* < */ 0x003c],
  Period: [/* . */ 0x002e, /* > */ 0x003e],
  Slash: [/* / */ 0x002f, /* ? */ 0x003f],
  Space: [/* SPACE */ 0x0020],
};
