// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_FR_OPTIMOT_ERGO: CharacterDict = {
  Backquote: [/* $ */ 0x0024, /* € */ 0x20ac, /* £ */ 0x00a3, /* © */ 0x00a9],
  Digit1: [/* « */ 0x00ab, /* 1 */ 0x0031, /* “ */ 0x201c, /* ¼ */ 0x00bc],
  Digit2: [/* » */ 0x00bb, /* 2 */ 0x0032, /* ” */ 0x201d, /* ½ */ 0x00bd],
  Digit3: [/* " */ 0x0022, /* 3 */ 0x0033, /* „ */ 0x201e, /* ¾ */ 0x00be],
  Digit4: [/* - */ 0x002d, /* 4 */ 0x0034, /* ‑ */ 0x2011, /* ⅓ */ 0x2153],
  Digit5: [/* + */ 0x002b, /* 5 */ 0x0035, /* ± */ 0x00b1, /* ⅔ */ 0x2154],
  Digit6: [/* * */ 0x002a, /* 6 */ 0x0036, /* × */ 0x00d7, /* ✻ */ 0x273b],
  Digit7: [/* / */ 0x002f, /* 7 */ 0x0037, /* \ */ 0x005c, /* ÷ */ 0x00f7],
  Digit8: [/* = */ 0x003d, /* 8 */ 0x0038, /* ≠ */ 0x2260, /* ≈ */ 0x2248],
  Digit9: [/* ( */ 0x0028, /* 9 */ 0x0039, /* [ */ 0x005b, /* ′ */ 0x2032],
  Digit0: [/* ) */ 0x0029, /* 0 */ 0x0030, /* ] */ 0x005d, /* ″ */ 0x2033],
  Minus: [/* @ */ 0x0040, /* _ */ 0x005f, /* − */ 0x2212, /* ‒ */ 0x2012],
  Equal: [/* # */ 0x0023, /* % */ 0x0025, /* ° */ 0x00b0, /* º */ 0x00ba],
  KeyQ: [/* à */ 0x00e0, /* À */ 0x00c0, /* < */ 0x003c, /* ≤ */ 0x2264],
  KeyW: [/* j */ 0x006a, /* J */ 0x004a, /* > */ 0x003e, /* ≥ */ 0x2265],
  KeyE: [/* o */ 0x006f, /* O */ 0x004f, /* œ */ 0x0153, /* Œ */ 0x0152],
  KeyR: [/* é */ 0x00e9, /* É */ 0x00c9, { dead: /* COMBINING ACUTE ACCENT */ 0x0301 }],
  KeyT: [/* b */ 0x0062, /* B */ 0x0042, /* — */ 0x2014, /* ❏ */ 0x274f],
  KeyY: [/* f */ 0x0066, /* F */ 0x0046, /* ‘ */ 0x2018, /* ✦ */ 0x2726],
  KeyU: [/* d */ 0x0064, /* D */ 0x0044, /* { */ 0x007b, /* † */ 0x2020],
  KeyI: [/* l */ 0x006c, /* L */ 0x004c, /* } */ 0x007d, /* ‡ */ 0x2021],
  KeyO: [/* ’ */ 0x2019, /* ? */ 0x003f, /* ’ */ 0x2019, /* ¿ */ 0x00bf],
  KeyP: [/* q */ 0x0071, /* Q */ 0x0051, { dead: /* COMBINING RING ABOVE */ 0x030a }, /* ⸮ */ 0x2e2e],
  BracketLeft: [/* x */ 0x0078, /* X */ 0x0058, /* | */ 0x007c, /* ¦ */ 0x00a6],
  BracketRight: [/* z */ 0x007a, /* Z */ 0x005a, null, /* ™ */ 0x2122],
  Backslash: [/* ç */ 0x00e7, /* Ç */ 0x00c7, { dead: /* COMBINING ACUTE ACCENT */ 0x0301 }, /* 🄯 */ 0x1f12f],
  KeyA: [/* a */ 0x0061, /* A */ 0x0041, /* æ */ 0x00e6, /* Æ */ 0x00c6],
  KeyS: [/* i */ 0x0069, /* I */ 0x0049, /* ₂ */ 0x2082, /* § */ 0x00a7],
  KeyD: [/* e */ 0x0065, /* E */ 0x0045, /* ² */ 0x00b2, /* ¶ */ 0x00b6],
  KeyF: [/* u */ 0x0075, /* U */ 0x0055, /* ù */ 0x00f9, /* Ù */ 0x00d9],
  KeyG: [/* , */ 0x002c, /* ; */ 0x003b, /* – */ 0x2013, /* ✓ */ 0x2713],
  KeyH: [/* p */ 0x0070, /* P */ 0x0050, /* ` */ 0x0060, /* ★ */ 0x2605],
  KeyJ: [/* t */ 0x0074, /* T */ 0x0054, /* & */ 0x0026, /* ⬅ */ 0x2b05],
  KeyK: [/* s */ 0x0073, /* S */ 0x0053, null, /* ⬇ */ 0x2b07],
  KeyL: [/* r */ 0x0072, /* R */ 0x0052, null, /* ⬆ */ 0x2b06],
  Semicolon: [/* n */ 0x006e, /* N */ 0x004e, { dead: /* COMBINING TILDE */ 0x0303 }, /* ⮕ */ 0x2b95],
  Quote: [{ dead: /* COMBINING CIRCUMFLEX ACCENT */ 0x0302 }, /* ! */ 0x0021, { dead: /* COMBINING DIAERESIS */ 0x0308 }, /* ¡ */ 0x00a1],
  IntlBackslash: [{ dead: /* COMBINING DIAERESIS */ 0x0308 }, /* ‽ */ 0x203d, /* ø */ 0x00f8, /* Ø */ 0x00d8],
  KeyZ: [/* k */ 0x006b, /* K */ 0x004b],
  KeyX: [/* y */ 0x0079, /* Y */ 0x0059, { dead: /* COMBINING DOT ABOVE */ 0x0307 }, /* ⌥ */ 0x2325],
  KeyC: [/* è */ 0x00e8, /* È */ 0x00c8, { dead: /* COMBINING GRAVE ACCENT */ 0x0300 }, /* ⌘ */ 0x2318],
  KeyV: [/* . */ 0x002e, /* : */ 0x003a, /* … */ 0x2026, /* · */ 0x00b7],
  KeyB: [/* w */ 0x0077, /* W */ 0x0057, /* • */ 0x2022, /* ✗ */ 0x2717],
  KeyN: [/* g */ 0x0067, /* G */ 0x0047, null, /* ✣ */ 0x2723],
  KeyM: [/* c */ 0x0063, /* C */ 0x0043, { dead: /* COMBINING CEDILLA */ 0x0327 }, /* ♠ */ 0x2660],
  Comma: [/* m */ 0x006d, /* M */ 0x004d, { dead: /* COMBINING MACRON */ 0x0304 }, /* ♥ */ 0x2665],
  Period: [/* h */ 0x0068, /* H */ 0x0048, { dead: /* COMBINING BREVE */ 0x0306 }, /* ♦ */ 0x2666],
  Slash: [/* v */ 0x0076, /* V */ 0x0056, { dead: /* COMBINING CARON */ 0x030c }, /* ♣ */ 0x2663],
  Space: [/* SPACE */ 0x0020],
};
