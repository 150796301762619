// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_AR_SA_102: CharacterDict = {
  Backquote: [/* > */ 0x003e, /* < */ 0x003c],
  Digit1: [/* 1 */ 0x0031, /* ! */ 0x0021],
  Digit2: [/* 2 */ 0x0032, /* @ */ 0x0040],
  Digit3: [/* 3 */ 0x0033, /* # */ 0x0023],
  Digit4: [/* 4 */ 0x0034, /* $ */ 0x0024, /* ¤ */ 0x00a4],
  Digit5: [/* 5 */ 0x0035, /* % */ 0x0025],
  Digit6: [/* 6 */ 0x0036, /* ^ */ 0x005e],
  Digit7: [/* 7 */ 0x0037, /* & */ 0x0026],
  Digit8: [/* 8 */ 0x0038, /* * */ 0x002a],
  Digit9: [/* 9 */ 0x0039, /* ) */ 0x0029],
  Digit0: [/* 0 */ 0x0030, /* ( */ 0x0028],
  Minus: [/* - */ 0x002d, /* _ */ 0x005f],
  Equal: [/* = */ 0x003d, /* + */ 0x002b],
  KeyQ: [/* ض */ 0x0636, /* َ */ 0x064e, { ligature: "َّ" }],
  KeyW: [/* ص */ 0x0635, /* ً */ 0x064b, { ligature: "ًّ" }],
  KeyE: [/* ث */ 0x062b, /* ُ */ 0x064f, { ligature: "ُّ" }],
  KeyR: [/* ق */ 0x0642, /* ٌ */ 0x064c, { ligature: "ٌّ" }],
  KeyT: [/* ف */ 0x0641, { ligature: "لإ" }],
  KeyY: [/* غ */ 0x063a, /* إ */ 0x0625],
  KeyU: [/* ع */ 0x0639, /* ‘ */ 0x2018],
  KeyI: [/* ه */ 0x0647, /* ÷ */ 0x00f7],
  KeyO: [/* خ */ 0x062e, /* × */ 0x00d7],
  KeyP: [/* ح */ 0x062d, /* ؛ */ 0x061b],
  BracketLeft: [/* ج */ 0x062c, /* } */ 0x007d],
  BracketRight: [/* د */ 0x062f, /* { */ 0x007b],
  Backslash: [/* ذ */ 0x0630, /* ّ */ 0x0651],
  KeyA: [/* ش */ 0x0634, /* \ */ 0x005c],
  KeyS: [/* س */ 0x0633],
  KeyD: [/* ي */ 0x064a, /* ] */ 0x005d],
  KeyF: [/* ب */ 0x0628, /* [ */ 0x005b],
  KeyG: [/* ل */ 0x0644, { ligature: "لأ" }],
  KeyH: [/* ا */ 0x0627, /* أ */ 0x0623],
  KeyJ: [/* ت */ 0x062a, /* ـ */ 0x0640],
  KeyK: [/* ن */ 0x0646, /* ، */ 0x060c],
  KeyL: [/* م */ 0x0645, /* / */ 0x002f],
  Semicolon: [/* ك */ 0x0643, /* : */ 0x003a],
  Quote: [/* ط */ 0x0637, /* " */ 0x0022],
  IntlBackslash: [/* ـ */ 0x0640, /* | */ 0x007c],
  KeyZ: [/* ئ */ 0x0626, /* ~ */ 0x007e],
  KeyX: [/* ء */ 0x0621, /* ْ */ 0x0652],
  KeyC: [/* ؤ */ 0x0624, /* ِ */ 0x0650, { ligature: "ِّ" }],
  KeyV: [/* ر */ 0x0631, /* ٍ */ 0x064d, { ligature: "ٍّ" }],
  KeyB: [{ ligature: "لا" }, { ligature: "لآ" }],
  KeyN: [/* ى */ 0x0649, /* آ */ 0x0622],
  KeyM: [/* ة */ 0x0629, /* ’ */ 0x2019],
  Comma: [/* و */ 0x0648, /* , */ 0x002c],
  Period: [/* ز */ 0x0632, /* . */ 0x002e],
  Slash: [/* ظ */ 0x0638, /* ؟ */ 0x061f],
  Space: [/* SPACE */ 0x0020],
};
