// Generated file, do not edit.

import { type CharacterDict } from "../types.ts";

// prettier-ignore
export const LAYOUT_DE_BONE: CharacterDict = {
  Backquote: [{ dead: /* COMBINING CIRCUMFLEX ACCENT */ 0x0302 }, { dead: /* COMBINING CARON */ 0x030c }],
  Digit1: [/* 1 */ 0x0031, /* ° */ 0x00b0, /* ¹ */ 0x00b9, /* ₁ */ 0x2081],
  Digit2: [/* 2 */ 0x0032, /* § */ 0x00a7, /* ² */ 0x00b2, /* ₂ */ 0x2082],
  Digit3: [/* 3 */ 0x0033, /* ℓ */ 0x2113, /* ³ */ 0x00b3, /* ₃ */ 0x2083],
  Digit4: [/* 4 */ 0x0034, /* » */ 0x00bb, /* › */ 0x203a, /* ♀ */ 0x2640],
  Digit5: [/* 5 */ 0x0035, /* « */ 0x00ab, /* ‹ */ 0x2039, /* ♂ */ 0x2642],
  Digit6: [/* 6 */ 0x0036, /* $ */ 0x0024, /* ¢ */ 0x00a2, /* ⚥ */ 0x26a5],
  Digit7: [/* 7 */ 0x0037, /* € */ 0x20ac, /* ¥ */ 0x00a5, /* ϰ */ 0x03f0],
  Digit8: [/* 8 */ 0x0038, /* „ */ 0x201e, /* ‚ */ 0x201a, /* ⟨ */ 0x27e8],
  Digit9: [/* 9 */ 0x0039, /* “ */ 0x201c, /* ‘ */ 0x2018, /* ⟩ */ 0x27e9],
  Digit0: [/* 0 */ 0x0030, /* ” */ 0x201d, /* ’ */ 0x2019, /* ₀ */ 0x2080],
  Minus: [/* - */ 0x002d, /* — */ 0x2014, null, /* ‑ */ 0x2011],
  Equal: [{ dead: /* COMBINING GRAVE ACCENT */ 0x0300 }, { dead: /* COMBINING CEDILLA */ 0x0327 }, { dead: /* COMBINING DIAERESIS */ 0x0308 }],
  KeyQ: [/* j */ 0x006a, /* J */ 0x004a, /* … */ 0x2026, /* θ */ 0x03b8],
  KeyW: [/* d */ 0x0064, /* D */ 0x0044, /* _ */ 0x005f, /* δ */ 0x03b4],
  KeyE: [/* u */ 0x0075, /* U */ 0x0055, /* [ */ 0x005b],
  KeyR: [/* a */ 0x0061, /* A */ 0x0041, /* ] */ 0x005d, /* α */ 0x03b1],
  KeyT: [/* x */ 0x0078, /* X */ 0x0058, /* ^ */ 0x005e, /* ξ */ 0x03be],
  KeyY: [/* p */ 0x0070, /* P */ 0x0050, /* ! */ 0x0021, /* π */ 0x03c0],
  KeyU: [/* h */ 0x0068, /* H */ 0x0048, /* < */ 0x003c, /* ψ */ 0x03c8],
  KeyI: [/* l */ 0x006c, /* L */ 0x004c, /* > */ 0x003e, /* λ */ 0x03bb],
  KeyO: [/* m */ 0x006d, /* M */ 0x004d, /* = */ 0x003d, /* μ */ 0x03bc],
  KeyP: [/* w */ 0x0077, /* W */ 0x0057, /* & */ 0x0026, /* ω */ 0x03c9],
  BracketLeft: [/* ß */ 0x00df, /* ẞ */ 0x1e9e, /* ſ */ 0x017f, /* ϱ */ 0x03f1],
  BracketRight: [{ dead: /* COMBINING ACUTE ACCENT */ 0x0301 }, { dead: /* COMBINING TILDE */ 0x0303 }],
  KeyA: [/* c */ 0x0063, /* C */ 0x0043, /* \ */ 0x005c, /* χ */ 0x03c7],
  KeyS: [/* t */ 0x0074, /* T */ 0x0054, /* / */ 0x002f, /* τ */ 0x03c4],
  KeyD: [/* i */ 0x0069, /* I */ 0x0049, /* { */ 0x007b, /* ι */ 0x03b9],
  KeyF: [/* e */ 0x0065, /* E */ 0x0045, /* } */ 0x007d, /* ε */ 0x03b5],
  KeyG: [/* o */ 0x006f, /* O */ 0x004f, /* * */ 0x002a, /* γ */ 0x03b3],
  KeyH: [/* b */ 0x0062, /* B */ 0x0042, /* ? */ 0x003f, /* β */ 0x03b2],
  KeyJ: [/* n */ 0x006e, /* N */ 0x004e, /* ( */ 0x0028, /* ν */ 0x03bd],
  KeyK: [/* r */ 0x0072, /* R */ 0x0052, /* ) */ 0x0029, /* κ */ 0x03ba],
  KeyL: [/* s */ 0x0073, /* S */ 0x0053, /* - */ 0x002d, /* σ */ 0x03c3],
  Semicolon: [/* g */ 0x0067, /* G */ 0x0047, /* : */ 0x003a, /* γ */ 0x03b3],
  Quote: [/* q */ 0x0071, /* Q */ 0x0051, /* @ */ 0x0040, /* ϕ */ 0x03d5],
  KeyZ: [/* f */ 0x0066, /* F */ 0x0046, /* # */ 0x0023],
  KeyX: [/* v */ 0x0076, /* V */ 0x0056, /* $ */ 0x0024],
  KeyC: [/* ü */ 0x00fc, /* Ü */ 0x00dc, /* | */ 0x007c],
  KeyV: [/* ä */ 0x00e4, /* Ä */ 0x00c4, /* ~ */ 0x007e, /* η */ 0x03b7],
  KeyB: [/* ö */ 0x00f6, /* Ö */ 0x00d6, /* ` */ 0x0060, /* ϵ */ 0x03f5],
  KeyN: [/* y */ 0x0079, /* Y */ 0x0059, /* + */ 0x002b, /* υ */ 0x03c5],
  KeyM: [/* z */ 0x007a, /* Z */ 0x005a, /* % */ 0x0025, /* ζ */ 0x03b6],
  Comma: [/* , */ 0x002c, /* – */ 0x2013, /* " */ 0x0022, /* ϱ */ 0x03f1],
  Period: [/* . */ 0x002e, /* • */ 0x2022, /* ; */ 0x003b, /* ϑ */ 0x03d1],
  Slash: [/* k */ 0x006b, /* K */ 0x004b, /* ; */ 0x003b, /* κ */ 0x03ba],
  Space: [/* SPACE */ 0x0020],
};
